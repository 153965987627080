import { AutoplayVideo } from "../AutoplayVideo";
import { NewFile } from "../NewFile";
import { ExtraLargeTitle } from "../Primitives/ExtraLargeTitle";
import { SmallSubtitle } from "../Primitives/SmallSubtitle";

import heroVideo from "~/assets/home/JsonHero2.mp4";

const jsonHeroTitle = "JSON Viewer";
const jsonHeroSlogan = "AI之家JSON数据可视化解析工具";

export function HomeHeroSection() {
  return (
    <div
      className={`flex items-stretch flex-col md:flex-row lg:p-6 lg:pb-16 pt-20 lg:pt-32 h-100vh justify-center`}
    >
      {/*<div className="self-center flex align-center justify-center p-6 md:w-3/4 w-full">
        <div className="max-w-3xl">
          <AutoplayVideo src={heroVideo} />
        </div>
      </div>*/}
      <div className="self-center align-center justify-center p-2 md:w-3/4 max-w-full">
        <div className="max-w-full">
          <ExtraLargeTitle className="text-lime-300">
            {jsonHeroTitle}
          </ExtraLargeTitle>
          <ExtraLargeTitle className="text-white mb-4">
            {jsonHeroSlogan}
          </ExtraLargeTitle>
          <SmallSubtitle className="text-slate-200 mb-8">

          </SmallSubtitle>
          <NewFile />
        </div>
      </div>
    </div>
  );
}
